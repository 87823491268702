export enum ModuleEnum {
    Base = 1,
    Complaint,
}

export enum AppealRegisterMode {
    sentAppeals = 1,
    sentToOrganization,
    myOrganizations,
    assignedToMe,
    myAppeals,
    myAppealsParalegal,
}

export enum AppealState {
    Created = 1,
    Processing,
    Returned,
    Changes,
    Rejected,
    InWork,
    Redirected,
    Paused,
    Completed,
    Canceled,
}

export enum OrgRoles {
    Donor = 1,
    Recipient,
    SubRecipient,
    ExternalOrganizations,
    ServiceDeliveryPoint,
    Appeal,
    LegalAssistance,
    MedicalOrganization,
    DKVS,
}

export enum AppealStateEnum {
    Created = 1,
    Processing,
    Returned,
    Changes,
    Rejected,
    InWork,
    Redirected,
    Paused,
    Completed,
    Canceled,
}

export enum ClientCategoryEnum {
    NoCategory = 1,
    InjectionDrugs = 2,
    ManWhoHasSexWithMen = 6,
    CommercialSex = 3,
    Transgender = 11,
    InjectionPartnerHIV = 23,
    SexualPartners = 10,
    NonInjectionDrugs = 5,
    Prisoner = 4,
    NoPermanentResidence = 7,
    InternallyMovedPerson = 9,
    Migrant = 8,
    CivilDefense = 20,
    Military = 12,
    MilitaryPartners = 21,
    GenderViolence = 22,
    ReleasedFromPenitentiary = 19,
    AlcoholAbuse = 17,
    TBContact = 13,
    MedicalWorkers = 15,
    BelowPovertyLine = 18,
    ConcomitantDiseases = 16,
    HarmfulWorkingConditions = 14,
}

export enum FunctionalStatusEnum {
    AbleToWork = 1,
    AbleSelfService,
    NeedsHelp,
    Unknown,
}

export enum DisabilityGroupEnum {
    Group1 = 1,
    Group2,
    Group3,
    DisabledChild,
    Empty,
    NeedForReRegistration,
    Unknown,
}

export enum SocialHelpEnum {
    HelpingHIVChild = 1,
    LowIncomeFamily,
    Subsidy,
    Pregnancy,
    Childbirth,
    ThreeYears,
    Pension,
    Custody,
    NotReceive,
    Need,
    Other,
    Unknown,
    SingleMothersChildSupport,
}

export enum СlientRightsProtectionEnum {
    ViolationOfRights = 1,
    RightsNotViolated,
    Unknown,
}

export enum IdentityDocumentsEnum {
    Available = 1,
    Missing,
    Unknown,
}

export enum ClientTypeEnum {
    Negative = 1,
    Positive,
    Unspecified,
    RemovedStatus,
}

export enum CommunicationTypeEnum {
    HomePhone = 1,
    WorkPhone,
    OtherPhone,
    Email,
    MainPhone,
}

export enum EducationLevelEnum {
    BasicSecondary = 1,
    UpperSecondary,
    Technical,
    Higher,
    HigherFull,
    GeneralEducation,
    NotOrganizedChildhood,
    Unknown,
}

export enum EmploymentEnum {
    Working = 1,
    Unemployed,
    Learns,
    Housewife,
    Pensioner,
    DoesNotApply,
    Unknown,
}

export enum GenderEnum {
    Male = 1,
    Female,
}

export enum IdentDocTypeEnum {
    Passport = 1,
    PensionerCertificate = 2,
    BirthCertificate = 3,
    DriverLicense = 4,
    biopassport = 5,
    TEMPORARY_RESIDENCE_PERMIT = 6,
    TEMPORARY_CERTIFICATE = 7,
    SSN = 8,
    REFUGEE_CERTIFICATE = 9,
    PERMANENT_RESIDENCE_PERMIT = 10,
    COMPLEMENTARY_PROTECTION_CERTIFICATE = 11,
    ChernobylLiquidatorCat2 = 12,
    ChernobylLiquidatorCat3 = 13,
    ChernobylVictimAdult1 = 14,
    ChernobylVictimAdult2 = 15,
    ChildChernobylVictim = 16,
    ChernobylLiquidatorCat1 = 17,
    ChernobylVictimAdult3 = 18,
    TEMPORARY_PASSPORT = 19,
}

export enum MaritalStatusEnum {
    Single = 1,
    Other = 2,
    Separated = 3,
    Widow = 4,
    Married = 5,
    Unknown,
    NotApplicable,
}

export enum ProjectRoleEnum {
    Donor = 1,
    Recipient,
    SubRecipient,
    ExternalOrganizations,
    ServiceDeliveryPoint,
    Appeal,
    LegalAssistance,
    MedicalOrganization,
    DKVS,
}

export enum TreatmentARTsTypeEnum {
    Prescription = 1,
    Change,
    Replacement,
    Interruption,
    Stop,
    CD,
    VN,
    CommitmentLevel,
}

export enum ProjectStateEnum {
    New = <any>1,
    InProcess = <any>2,
    Completed = <any>3,
}

export enum ResultTypeEnum {
    None = <any>1,
    YesNo = <any>2,
    Numeric = <any>3,
    YesNoUnknown = <any>4,
}

export enum UserRoleEnum {
    Admin = 1,
    AdminDonor,
    AdminRecipient,
    AdminSubRecipient,
    Consultant,
    Root,
    Client,
    Integration,
    AdminParalegal,
    Paralegal,
    AdminSubRecipientPlus,
    AdminLawyer,
    Lawyer,
}

export enum VerificationTypeEnum {
    App = 1,
    SMS,
    VerificationNotRequired,
    VerificationByAdmin,
    Call,
    AdminCreated,
}

export enum PartnerTypeEnum {
    Child = 1,
    Injection,
    Sexual,
}

export enum PartnerInfoEnum {
    InformingClient = 1,
    InformingDoctor,
    ContractInforming,
    InformingDoctorAndClient,
    RiskToNSPP,
    PartnerHasPositiveStatus,
}

export enum ClientReasonForTestingEnum {
    PartnerSexual = 1,
    PartnerSyringe,
    TB,
    SkinDisease,
    OtherMedicalIndications,
    OtherNonMedicalIndications,
    SelfConversion,
    ChildOfHIVPositive,
    Other,
}

export enum ResultDictionaryEnum {
    Positive = 1,
    Negative,
    Unknown,
    YesNoUnknown,
}

export enum AppointmentStateEnum {
    New = 1,
    Started,
    Completed,
    Accepted,
    Rejected,
    CompletedUnferified,
    Canceled,
}

export enum SecondGenderEnum {
    Man = 1,
    Woman,
    Transman,
    Transwoman,
    NonbinaryPerson,
    OtherOption,
}

export enum LegalAssistanceEnum {
    First = 1,
    Second,
}

export enum InvolvementMethod {
    Self = 1,
    RedirectedFromMedicalWorker,
    Repeat,
    SocialWorkerHelp,
    RedirectedFromPreventionPrograms,
}

export enum PregnancyResult {
    Childbirth = 1,
    SpontaneousAbortion,
    ArtificialAbortion,
}

export enum HepatitisOperType {
    HepatitisBExamination = 1,
    HepatitisBVaccination,
    HepatitisCExamination,
    HepatitisCTreatment,
}

export enum HepatitisBExaminationType {
    HepatitisBSurfaceAntigen = 1,
    AntibodiesToHepatitisBBuclearAntigen,
    HepatitisBPCR,
}

export enum HepatitisBVaccinationDose {
    OneDose = 1,
    SecondDose,
    ThirdDose,
}

export enum HepatitisCExaminationType {
    HCVAntibodyTesting = 1,
    HCVRNATestStart,
    Genotype,
    LiverDamageStage,
    HBsAg,
    ALTLevels,
    ACTLevels,
    HCVRNATestEnd,
}

export enum HepatitisCExaminationGenotypeResult {
    OneA = 1,
    OneB,
    Two,
    Three,
    Four,
    Five,
    Six,
}

export enum HepatitisCExaminationLiverDamageStage {
    F0 = 1,
    F1,
    F2,
    F3,
    F4,
}

export enum HepatitisCSupportEffectiveness {
    FullCourseCompleted = 1,
    SeparationFromTreatment,
    Died,
    RefusalOfSupport,
    Moved,
}

export enum HepatitisCTreatmentResult {
    Healed = 1,
    TreatmentFailure,
    Died,
    NoInformation,
    Moved,
}

export enum HepatitisBVaccine {
    Engerix = 1,
    EUVAX,
    Twinrix,
}

export enum PeriodStatus {
    Open = 1,
    Closed,
}

export enum TuberculosisOperType {
    Screening = 1,
    Diagnosis,
    Treatment,
    Chemoprophylaxis,
}

export enum ScreeningResult {
    Suspicion = 1,
    NoSuspicion,
}

export enum DiagnosticMethod {
    GeneXpert = 1,
    SputumAnalysis,
    Biopsy,
    CT,
    MRI,
    PCR,
    Radiological,
    Bacterioscopic,
    Bacteriological,
    IGRA,
}

export enum DiagnosticResult {
    EstablishedDiagnosis = 1,
    NotConfirmed,
    InWork,
}

export enum TBTreatmentCategory {
    One = 1,
    Two,
    Three,
    Four,
}

export enum RadiologicalResult {
    NotDone = 1,
    PositiveDynamics,
    NegativeDynamics,
    Unchanged,
    Stabilization,
}

export enum TBTreatmentResult {
    Cured = 1,
    TreatmentIsComplete,
    Died,
    UnsuccessfulTreatment,
    InterruptedTreatment,
    RemovedDiagnosis,
    Moved,
    SupportRefusal,
    NotEvaluatedResult,
}

export enum ChemoprophylaxisSupportEffectiveness {
    FullTreatmentCcoursCompleted = 1,
    TreatmentRefusal,
    Died,
    SupportRefusal,
    MovedToOtherOrg,
}

export enum SpecialRootsEnum {
    ViewAppointmentByContract = 1,
    CreateConsultant,
    MergeClients,
    MassClientRemovalFromPackage,
    ProjectProgr,
    AdminRecByProject,
    MassAppointmentCreate,
}

export enum TimePeriodEnum {
    Day = 1,
    Week,
    Month,
    Quarter,
    HalfYear,
    Year,
}
